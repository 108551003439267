import { Option } from '../components/input'

export interface Item {
    id: string 
    name: string
}

export interface HierarchyItem extends Item {
    parentId?: string 
    isDirt: boolean
    isPortal: boolean
}

export function getHierarchyItem(hierarchyItems: Array<HierarchyItem>, id: string): HierarchyItem | undefined {
    return hierarchyItems.find(hi => hi.id == id)
}

export function getHierarchyItems(hierarchyItems: Array<HierarchyItem> | null, parentId?: string): Array<HierarchyItem> {
    if (!hierarchyItems) return []
    else return hierarchyItems.filter(hi => hi.parentId == parentId)
}

export function getHierarchyOptions(hierarchyItems: Array<HierarchyItem> | null, parentId?: string, isDisabled?: (hi: HierarchyItem) => boolean): Array<Option> {
    return getHierarchyItems(hierarchyItems, parentId)
        .map(hi => {
            return { text: hi.name, value: hi.id, disabled: isDisabled && isDisabled(hi) }
        })
}

export type FormType = 'pif' | 'systembonus' | 'finalquestionnaire'

export interface HierarchyCoords {
    applicationTypeName?: string
    formType?: FormType
    intakeKey?: string
    pifId?: number
}
