import { FormType } from './Hierarchy'
import { assertNever } from '../utility'

export interface FormItem {
    formId: number
    name: string
    assignmentsPIF: Array<Assignment>
    assignmentsSystemBonus: Array<Assignment>
    assignmentsFinalQuestionnaire: Array<Assignment>
}

export function getAssignments(formItem: FormItem, formType: FormType): Array<Assignment> {
    switch (formType) {
        case 'pif': return formItem.assignmentsPIF
        case 'systembonus': return formItem.assignmentsSystemBonus
        case 'finalquestionnaire': return formItem.assignmentsFinalQuestionnaire
        default: return assertNever(formType)
    }
}

export interface Assignment {
    formId?: number
    bmpKey: string 
    intakeKey: string 
}

export interface FormDefinitionsImportResult {
    deletedForms: Array<FormItem>
    createdForms: Array<FormItem>
    updatedForms: Array<FormItem>
    assignedForms: Array<FormItem>
}

export interface FormDeploymentResult {
    capForms: Array<FormItem>
    dirtForms: Array<FormItem>
}

export interface FormResponsesImportResult {
    applicationsAnswered: Array<string>
    answersCreated: number
    answersDeleted: number
    importErrors: Array<string>
}
