import React from 'react'
import { Container, Row, Col, CustomInput} from 'reactstrap'

import { PIF_FORM_TYPE, SYSTEMS_BONUS_FORM_TYPE, FINAL_QUESTIONNAIRE_FORM_TYPE } from '../../Constants'
import ConfirmModal from '../modals/ConfirmModal' 
import { InputChangedHandler } from '../input'
import { DropDown } from '../DropDown';
import { HierarchyItem, getHierarchyOptions, FormType } from '../../models/Hierarchy'
import AppContext from '../../AppContext'
import { Assignment } from '../../models/FormItem';

export interface AssignModalProps {
    projectInformationFormId: number
    intakeKey: string,
    formTypeKey: FormType,
    formTypeValue: string,

    // TODO: refactor assignments into keyed object
    allDeploymentsPif: Array<Assignment>,
    allDeploymentsSystemBonus: Array<Assignment>,

    onDismiss: () => void,
    onAssign: (deployment: Assignment, formTypeKey: FormType, copy: boolean) => void
}

export interface AssignModalState {
    applicationType?: string 
    intakeKey?: string
    bmpKey?: string
    formTypeKey?: string
    createCopy: boolean
}

export class AssignModal extends React.Component<AssignModalProps, AssignModalState> {
    static displayName = AssignModal.name;

    static contextType = AppContext
    context!: React.ContextType<typeof AppContext>

    constructor(props: AssignModalProps) {
        super(props)
        this.state = { createCopy: false }
    }

    componentDidMount() {
        let intake = this.context.intakes!.find(i => i.id == this.props.intakeKey)
        if (intake ) {
            this.setState({  intakeKey: intake.id, applicationType: intake.parentId })
        }
    }

    formTypeChanged: InputChangedHandler = (val: string) => this.setState({ formTypeKey: val, applicationType: undefined, intakeKey: undefined, bmpKey: undefined })

    applicationTypeChanged: InputChangedHandler = (val: string) => this.setState({ applicationType: val, intakeKey: undefined, bmpKey: undefined })

    intakeChanged: InputChangedHandler = (val: string) => this.setState({ intakeKey: val, bmpKey: undefined })

    bmpChanged: InputChangedHandler = (val: string) => this.setState({ bmpKey: val })

    handleAssign = () => {
        if (this.state.intakeKey && this.state.bmpKey) {
            this.props.onAssign({             
                formId: this.props.projectInformationFormId,
                intakeKey: this.state.intakeKey,
                bmpKey: this.state.bmpKey
            }, this.props.formTypeKey,this.state.createCopy)
        }
    }

    isBmpDisabled = (hi: HierarchyItem) => {
        if (this.props.formTypeKey) {
            if (this.props.formTypeKey == PIF_FORM_TYPE ) { return this.props.allDeploymentsPif.some(d => d.bmpKey == hi.id && d.intakeKey == hi.parentId && !!d.formId) }
            if (this.props.formTypeKey == SYSTEMS_BONUS_FORM_TYPE) { return this.props.allDeploymentsSystemBonus.some(d => d.bmpKey == hi.id && d.intakeKey == hi.parentId && !!d.formId) }
            if (this.props.formTypeKey == FINAL_QUESTIONNAIRE_FORM_TYPE) { return this.props.allDeploymentsSystemBonus.some(d => d.bmpKey == hi.id && d.intakeKey == hi.parentId && !!d.formId) }
        }
        return true;
    }

    render() {
        return (
            <ConfirmModal header='Assign PIF' onDismiss={this.props.onDismiss} onConfirm={this.handleAssign} >
                    <Container>
                        <Row>
                            <Col md={{offset: 1, size: 10}}>
                            <div className='mb-3'>
                                Assign this {this.props.formTypeValue} to the following BMP/Intake:
                                </div>                             
                                <DropDown
                                    label='Application Type: '
                                    options={getHierarchyOptions(this.context.applicationTypes!)}
                                    value={this.state.applicationType}
                                    onChange={this.applicationTypeChanged}
                                    defaultText='-- SELECT --' />
                                <DropDown
                                    label='Intake: '
                                    options={getHierarchyOptions(this.context.intakes!, this.state.applicationType)}
                                    value={this.state.intakeKey}
                                    onChange={this.intakeChanged}
                                    defaultText='-- SELECT --' />
                                <DropDown
                                    label='BMP: '
                                    options={getHierarchyOptions(this.context.bmps!, this.state.intakeKey, this.isBmpDisabled)}
                                    value={this.state.bmpKey}
                                    onChange={this.bmpChanged}
                                    defaultText='-- SELECT --' />

                                <CustomInput
                                    id='chkCopy'
                                    type='checkbox'
                                    checked={this.state.createCopy}
                                    onChange={(e) => this.setState({ createCopy: e.target.checked })}
                                    label='Create a Copy' />
                            </Col>
                        </Row>
                    </Container>
            </ConfirmModal>
        )
    }
}

export default AssignModal
